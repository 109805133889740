<template>
    <v-container>
        <v-row class="justify-space-between">
            <v-col cols="8" md="6">
                <div class="text-h6 text-md-h5 font-weight-bold">
                    My Author Requests
                </div>
            </v-col>
            <v-col cols="auto">
                <v-btn
                    class="primary"
                    title="New Request"
                    :to="newRequestLink"
                    :small="$vuetify.breakpoint.smAndDown"
                >
                    <v-icon :left="$vuetify.breakpoint.mdAndUp" small>
                        plus
                    </v-icon>
                    {{ $vuetify.breakpoint.mdAndUp ? 'New Request' : null }}
                </v-btn>
            </v-col>
        </v-row>

        <v-row v-if="loading">
            <v-col v-if="$vuetify.breakpoint.smAndDown">
                <author-request-card loading />
            </v-col>
            <template v-else>
                <v-col v-for="i in 3" :key="'skeleton-loader-' + i" md="4">
                    <author-request-card loading />
                </v-col>
            </template>
        </v-row>

        <v-row v-else-if="requests.length">
            <v-col v-if="$vuetify.breakpoint.smAndDown">
                <carousel height="220px" :show-arrows="false">
                    <v-carousel-item
                        v-for="(request, index) in requests"
                        :key="'author-request-container--card-' + index"
                    >
                        <author-request-card
                            :request="request"
                            :action="getAction(request)"
                            @show-comments="showComments"
                        />
                    </v-carousel-item>
                </carousel>
            </v-col>
            <template v-else>
                <v-col
                    v-for="(request, index) in requests"
                    :key="'author-request-container--card-' + index"
                    cols="4"
                    class="d-flex"
                >
                    <author-request-card
                        :request="request"
                        :action="getAction(request)"
                        @show-comments="showComments"
                    />
                </v-col>
            </template>
        </v-row>

        <a-message-bar v-else>No requests submitted yet</a-message-bar>

        <v-row v-if="loading">
            <v-col
                cols="12"
                class="d-flex justify-end pt-5"
                style="padding-bottom: 10px"
            >
                <v-skeleton-loader loading type="text" width="70px" />
            </v-col>
        </v-row>
        <v-row v-else-if="requests.length">
            <v-col class="text-right">
                <router-link to="/author-requests/list">Show All</router-link>
            </v-col>
        </v-row>

        <assignment-comments
            :assignment-id="showCommentsForAssignmentId"
            @close="hideComments"
            @update="update"
        />
    </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component, { mixins } from 'vue-class-component';
import { mapGetters } from 'vuex';

import { UserId } from '@/mixins';

import { Carousel } from '@/components/Carousel';
import { AMessageBar } from '@/components/AMessageBar';
import { AuthorRequestCard } from '@/components/Authoring/AuthorRequestCard';
import { AssignmentComments } from '@/components/Authoring/AssignmentComments';

import AuthorRequestActionsFactory from '@/components/Authoring/AuthorRequestActions/AuthorRequestActionsFactory';

import type { AuthorRequest } from '@/types/AuthorRequest';

const AuthorRequestsSectionProps = Vue.extend({
    name: 'AuthorRequestsSection',
    props: {
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        },
        requests: {
            type: Array as PropType<AuthorRequest[]>,
            required: true
        }
    }
});

@Component({
    components: {
        AuthorRequestCard,
        Carousel,
        AssignmentComments,
        AMessageBar
    },
    computed: {
        ...mapGetters('user', ['isAuthor', 'isEditor'])
    }
})
export default class AuthorRequestsSection extends mixins(
    AuthorRequestsSectionProps,
    UserId
) {
    isAuthor!: boolean;
    isEditor!: boolean;

    showCommentsForAssignmentId = 0;

    get newRequestLink() {
        return ['/author-requests/create', this.activeUserId]
            .filter(Boolean)
            .join('?user_id=');
    }

    getAction(request: AuthorRequest) {
        const AuthoringActions = new AuthorRequestActionsFactory(
            request,
            this.isEditor,
            this.isAuthor
        );

        const actions = AuthoringActions.getActions();

        const editAction = actions.find(action =>
            ['write', 'edit'].includes(action.action)
        );

        const commentsAction = actions.find(
            action => action.action === 'review'
        );

        return editAction || commentsAction;
    }

    showComments(id: number) {
        this.showCommentsForAssignmentId = id;
    }

    hideComments() {
        this.showCommentsForAssignmentId = 0;
    }

    update() {
        this.$emit('update', true);
    }
}
</script>
