<template>
    <v-container>
        <template v-if="$vuetify.breakpoint.mdAndUp">
            <amp-banner
                v-if="(isEditor || isAuthor) && !isAdmin"
                class="my-4"
            />
            <welcome-banner v-else class="my-4" />
        </template>

        <announcements-client-selector
            v-if="isReseller"
            :clients="clients"
            :loading="isLoading"
            class="px-0"
        />

        <author-requests-section
            :requests="requests"
            :loading="isLoading"
            class="px-0"
            @update="update"
        />

        <v-spacer class="ma-12" />

        <announcements-section
            :announcements="announcements"
            :loading="isLoading"
            class="px-0"
        />

        <training-section class="mt-10" />

        <press-cable-user-welcome />

        <training-link v-if="canSeeTrainingLink && !isLoading" class="mt-4" />
    </v-container>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';

import { AmpBanner } from '@/components/AmpBanner';
import { WelcomeBanner } from '@/components/WelcomeBanner';
import { AnnouncementsClientSelector } from '@/components/AnnouncementsClientSelector';
import { TrainingSection, TrainingLink } from '@/components/Training';
import { PressCableUserWelcome } from '@/components/PressCableUserWelcome';

import { AuthorRequestsSection, AnnouncementsSection } from './sections';

import { UserId, Training, ListPaged } from '@/mixins';

import type { Announcement } from '@/types/Announcement';
import type { AuthorRequest } from '@/types/AuthorRequest';
import type { ClientUser } from '@/types/User';

@Component({
    components: {
        AmpBanner,
        AnnouncementsClientSelector,
        AnnouncementsSection,
        AuthorRequestsSection,
        TrainingSection,
        TrainingLink,
        WelcomeBanner,
        PressCableUserWelcome
    },
    computed: {
        ...mapState('clients', ['clients']),
        ...mapGetters('user', ['isEditor', 'isReseller'])
    }
})
export default class Announcements extends mixins(ListPaged, UserId, Training) {
    isEditor!: boolean;
    isReseller!: boolean;
    clients!: ClientUser[];

    announcements: Announcement[] = [];

    requests: AuthorRequest[] = [];

    endpoint = '/announcements';

    get queryParams() {
        return {
            user_id: this.userId,
            limit: 3,
            page: 1,
            sort: 'submitted',
            asc: 0,
            showArchived: 1
        };
    }

    onData(data: {
        announcements: Announcement[];
        authorRequests: AuthorRequest[];
        clients: Record<string, string>;
        length: number;
    }) {
        this.announcements = data.announcements;
        this.requests = data.authorRequests;
        this.$store.dispatch('clients/set', data.clients);
    }
}
</script>
