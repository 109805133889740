import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VRow,{staticClass:"justify-space-between"},[_c(VCol,{attrs:{"cols":"8","md":"6"}},[_c('div',{staticClass:"text-h6 text-md-h5 font-weight-bold"},[_vm._v("My Amps")])]),_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{staticClass:"white",attrs:{"title":"Create Amp","to":_vm.newAnnouncementLink,"small":_vm.$vuetify.breakpoint.smAndDown}},[_c(VIcon,{attrs:{"left":_vm.$vuetify.breakpoint.mdAndUp,"small":""}},[_vm._v(" plus ")]),_vm._v(" "+_vm._s(_vm.$vuetify.breakpoint.mdAndUp ? 'Create Amp' : null)+" ")],1)],1)],1),(_vm.loading)?_c(VRow,[(_vm.$vuetify.breakpoint.smAndDown)?_c(VCol,[_c('announcement-card',{attrs:{"loading":""}})],1):_vm._l((3),function(i){return _c(VCol,{key:'skeleton-loader-' + i,attrs:{"md":"4"}},[_c('announcement-card',{attrs:{"loading":""}})],1)})],2):(_vm.announcements.length)?_c(VRow,[(_vm.$vuetify.breakpoint.smAndDown)?_c(VCol,[_c('carousel',{attrs:{"height":"220px","show-arrows":false}},_vm._l((_vm.announcements),function(announcement,index){return _c(VCarouselItem,{key:'announcement-card-container--' + index},[_c('announcement-card',{attrs:{"announcement":announcement,"action":_vm.getAction(announcement)}})],1)}),1)],1):_vm._l((_vm.announcements),function(announcement,index){return _c(VCol,{key:'announcement-card-container--' + index,staticClass:"d-flex flex-grow-1",attrs:{"cols":"4"}},[_c('announcement-card',{attrs:{"announcement":announcement,"action":_vm.getAction(announcement)}})],1)})],2):_c('a-message-bar',[_vm._v("No Announcements Created Yet")]),(_vm.loading)?_c(VRow,[_c(VCol,{staticClass:"d-flex justify-end pt-5",staticStyle:{"padding-bottom":"10px"},attrs:{"cols":"12"}},[_c(VSkeletonLoader,{attrs:{"loading":"","type":"text","width":"70px"}})],1)],1):(_vm.announcements.length)?_c(VRow,[_c(VCol,{staticClass:"text-right"},[_c('router-link',{attrs:{"to":"/announcements/list"}},[_vm._v("Show All")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }