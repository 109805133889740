<template>
    <v-container>
        <v-row class="justify-space-between">
            <v-col cols="8" md="6">
                <div class="text-h6 text-md-h5 font-weight-bold">My Amps</div>
            </v-col>
            <v-col cols="auto">
                <v-btn
                    class="white"
                    title="Create Amp"
                    :to="newAnnouncementLink"
                    :small="$vuetify.breakpoint.smAndDown"
                >
                    <v-icon :left="$vuetify.breakpoint.mdAndUp" small>
                        plus
                    </v-icon>
                    {{ $vuetify.breakpoint.mdAndUp ? 'Create Amp' : null }}
                </v-btn>
            </v-col>
        </v-row>

        <v-row v-if="loading">
            <v-col v-if="$vuetify.breakpoint.smAndDown">
                <announcement-card loading />
            </v-col>
            <template v-else>
                <v-col v-for="i in 3" :key="'skeleton-loader-' + i" md="4">
                    <announcement-card loading />
                </v-col>
            </template>
        </v-row>

        <v-row v-else-if="announcements.length">
            <v-col v-if="$vuetify.breakpoint.smAndDown">
                <carousel height="220px" :show-arrows="false">
                    <v-carousel-item
                        v-for="(announcement, index) in announcements"
                        :key="'announcement-card-container--' + index"
                    >
                        <announcement-card
                            :announcement="announcement"
                            :action="getAction(announcement)"
                        />
                    </v-carousel-item>
                </carousel>
            </v-col>
            <template v-else>
                <v-col
                    v-for="(announcement, index) in announcements"
                    :key="'announcement-card-container--' + index"
                    cols="4"
                    class="d-flex flex-grow-1"
                >
                    <announcement-card
                        :announcement="announcement"
                        :action="getAction(announcement)"
                    />
                </v-col>
            </template>
        </v-row>

        <a-message-bar v-else>No Announcements Created Yet</a-message-bar>

        <v-row v-if="loading">
            <v-col
                cols="12"
                class="d-flex justify-end pt-5"
                style="padding-bottom: 10px"
            >
                <v-skeleton-loader loading type="text" width="70px" />
            </v-col>
        </v-row>
        <v-row v-else-if="announcements.length">
            <v-col class="text-right">
                <router-link to="/announcements/list">Show All</router-link>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component, { mixins } from 'vue-class-component';
import { mapGetters } from 'vuex';

import { UserId } from '@/mixins';

import { Carousel } from '@/components/Carousel';
import { AnnouncementCard } from '@/components/Announcements/AnnouncementCard';
import { AMessageBar } from '@/components/AMessageBar';

import AnnouncementActionsFactory from '@/components/Announcements/AnnouncementActions/AnnouncementActionsFactory';
import type { Announcement } from '@/types/Announcement';

const AnnouncementsSectionProps = Vue.extend({
    name: 'AnnouncementsSection',
    props: {
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        },
        announcements: {
            type: Array as PropType<Announcement[]>,
            required: true
        }
    }
});

@Component({
    components: { AnnouncementCard, Carousel, AMessageBar },
    computed: {
        ...mapGetters('user', ['isAuthor', 'isEditor'])
    }
})
export default class AnnouncementsSection extends mixins(
    AnnouncementsSectionProps,
    UserId
) {
    isAuthor!: boolean;
    isEditor!: boolean;

    get newAnnouncementLink() {
        return ['/announcements/create', this.activeUserId]
            .filter(Boolean)
            .join('?user_id=');
    }

    get isAuthoringTeamUser() {
        return this.isEditor || this.isAuthor;
    }

    getAction(announcement: Partial<Announcement>) {
        const AuthoringActions = new AnnouncementActionsFactory(
            announcement,
            false,
            this.isAuthoringTeamUser
        );

        const actions = AuthoringActions.getActions();
        const editAction = actions.find(action => action.action === 'edit');
        const reviewAction = actions.find(action => action.action === 'review');

        return editAction || reviewAction;
    }
}
</script>
